<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="bannerBox">
      <p>已成功估价车辆数量</p>
      <div class="banner">
        <div class="numBox">
          <span>1</span>
          <span>2</span>
          <span>3</span>
        </div>
        <img src="../../../public/img/point.png" />
        <div class="numBox">
          <span>1</span>
          <span>2</span>
          <span>3</span>
        </div>
      </div>
    </div>
    <el-card :body-style="{ padding: '0 0 100px 0' }" style="width:62.5%;margin: 20px auto;text-align: center;"
      class="box-card">
      <div>
        <p class="title">车辆估价:</p>
        <div class="list">
          <div class="list-lable">车辆进场方式:</div>
          <el-select @change="onChange" style="width: 100%;" v-model="value" placeholder="车辆送达方式">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="list">
          <div class="list-lable">车辆重量(吨):</div>
          <el-input v-model="form.weight" placeholder="请输入车重"></el-input>
        </div>
        <div class="list">
          <el-button @click="submit" style="background: #24A2AA;color: white;width: 100%;font-size: 18px;">立即估价
          </el-button>
        </div>
        <div class="last">
          <p class="p1">注：1.实际重量以车辆入厂称重值为准；</p>
          <p class="p2">2.残值款的计算取决于车辆送达方式和实际重量。</p>
        </div>
      </div>
      <div class="table">
        <el-table :data="data" border style="width: 100%" :header-cell-style="{
            'background-color': '#F4F4F4',
            color: '#999999'
          }">
          <el-table-column align="center" prop="type" label="常用车型" width="300">
          </el-table-column>
          <el-table-column align="center" prop="weight" label="参考重量" width="300">
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  computed: {
    ...mapState({
      get_CalculateCarValue: (state) => {
        return state.home.get_CalculateCarValue;
      },
    }),
  },
  data() {
    return {
      data: [
        {
          type: "小型面包车",
          weight: "1.0t",
        },
        {
          type: "微型轿车",
          weight: "0.8t",
        },
        {
          type: "小型轿车",
          weight: "1.1t",
        },
        {
          type: "小型越野客车",
          weight: "2.0t",
        },
        {
          type: "轻型普通货车",
          weight: "1.8t",
        },
        {
          type: "轻型厢式货车",
          weight: "2.1t",
        },
        {
          type: "小型普通客车",
          weight: "1.2t",
        },
        {
          type: "中型普通客车",
          weight: "3.0t",
        },
        {
          type: "大型普通客车",
          weight: "9.2t",
        },
        {
          type: "轿车",
          weight: "1.2t",
        },
        {
          type: "普通二轮摩托车",
          weight: "0.1t",
        },
      ],
      options: [
        {
          value: 1,
          label: "车主送达",
        },
        {
          value: 2,
          label: "拖车入场",
        },
      ],
      value: 2,
      form: {
        weight: "",
        price: "",
      },
      mode: 2,
      systemSettingType: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    onChange(value) {
      this.mode = value;
    },
    submit() {
      var that = this;
      if (that.mode == 1 && that.form.weight <= 2) {
        that.systemSettingType = 2;
      }
      if (that.mode == 2 && that.form.weight <= 2) {
        that.systemSettingType = 3;
      }
      if (that.mode == 1 && that.form.weight > 2) {
        that.systemSettingType = 4;
      }
      if (that.mode == 2 && that.form.weight > 2) {
        that.systemSettingType = 5;
      }
      var numReg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(that.form.weight)) {
        this.$message({
          type: "warning",
          message: "请输入数字或包含小数点的两位小数",
          duration: 2000,
          showClose: true,
        });
        return false;
      } else {
        that.$store
          .dispatch("home/CalculateCarValue", {
            SystemSettingType: that.systemSettingType,
            Weight: that.form.weight,
          })
          .then(() => {
            that.$confirm(that.get_CalculateCarValue + "元", "残值大概为", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            });
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bannerBox {
  height: 580px;
  width: 100%;
  background-size: 100%;
  background-image: url(/img/homeIcon/dalan.jpg);
  background-repeat: no-repeat;
  p {
    height: 160px;
    opacity: 0.8;
    border-radius: 8px;
    margin-right: 10px;
    font-size: 50px;
    font-family: PingFang SC;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 160px;
  }
}
.banner {
  // height: 580px;
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .numBox {
    span {
      display: inline-block;
      width: 100px;
      height: 160px;
      background: #ffffff;
      opacity: 0.8;
      border-radius: 8px;
      margin-right: 10px;
      font-size: 120px;
      font-family: PingFang SC;
      font-weight: bold;
      font-style: italic;
      color: #2197a2;
    }
  }
  img {
    width: 16px;
    height: 19px;
    margin-right: 10px;
  }
}
.box-card {
  position: relative;
  top: -140px;
  .title {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin: 49px 0 29px 0;
  }
  .list {
    width: 630px;
    height: 48px;
    margin: 0 auto;
    position: relative;
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
    .list-lable {
      width: 200px;
      position: absolute;
      text-align: right;
      color: #999999;
      left: -220px;
      font-size: 16px;
      top: 0;
    }
  }
  .last {
    width: 630px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 50px;
    p {
      text-align: left;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
    }
    .p2 {
      padding-left: 28px;
    }
  }
  .table {
    width: 602px; /* no */
    margin: 0 auto;
    margin-bottom: 50px;
  }
}
.lastBox {
  /deep/.el-table--border::after {
    display: none;
  }
}
/deep/.el-input__inner {
  background-color: #f4f4f4;
  border: none;
}
/deep/.el-input__inner::placeholder {
  color: #333333;
}
</style>
